import { StateMapping, UiState } from 'instantsearch.js';
import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';

export const stateMapping: (index: string) => StateMapping<UiState, UiState & { filters?: string }> = (index) => ({
  stateToRoute: (state) => ({
    ...singleIndex(index).stateToRoute(state),
    ...(state[index]?.configure?.filters && { filters: state[index]?.configure?.filters }),
  }),

  routeToState: (route) => {
    const { filters, ...routeWithoutFilters } = route;

    return {
      [index]: {
        ...singleIndex(index).routeToState(routeWithoutFilters)[index],
        ...(filters && { configure: { filters } }),
      },
    };
  },
});
