import { errorProd as errorLog, success as successLog } from '@/utilities/log';

/**
 * This request file is origined from Licensing Frontend. If any changes are made please consider retro-fit it
 * to licensing Frontend with a Jira ticket
 */

type Method = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
type RequestData = {
  headers?: Record<string, string>;
  /** Anything that can be serialized with JSON.stringify() */
  body?: any;
};

const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    const hostname = window.location.hostname;
    return hostname.split('.')[0];
  }
  return '';
};

export const DAM_URL = process.env.NODE_ENV === 'development' ? `http://${getSubdomain()}.dam.local:3001` : ``;

/**
 * Dam Request
 */
export const damRequest = function (method: Method, uri: string, token?: string, data?: RequestData) {
  return request(DAM_URL, method, uri, token, data);
};

const request = async function (root_url: string, method: Method, uri: string, token?: string, data?: RequestData) {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(data?.headers ?? {}),
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  let uriRequest = uri;

  if (process.env.NODE_ENV === 'development') {
    if (uri.includes('?')) {
      uriRequest += '&XDEBUG_SESSION=PHPSTORM';
    } else {
      uriRequest += '?XDEBUG_SESSION=PHPSTORM';
    }
  }

  const response = await fetch(`${root_url}${uriRequest}`, {
    method,
    ...data,
    headers,
    ...(data?.body && { body: JSON.stringify(data.body) }),
  });

  const contentType = response.headers.get('content-type');
  const responseData = contentType && contentType.includes('application/json') ? await response.json() : null;

  if (!response.ok) {
    throw responseData;
  }

  successLog('API Response', uri, {
    request: {
      uri,
      data,
    },
    data: responseData,
    response,
  });
  return responseData ?? response.statusText;
};
