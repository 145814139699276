import { damRequest as request } from '@/hooks/damRequest';

const BASE = '/api/v1/internal';

/**
 * Sdk > User Profile Methods
 *
 */

/**
 * Gets the current user details
 * @returns {Object} Customer Profile
 */
export const getSelf = (params?: object, token?: string) => request('GET', `${BASE}/self`, token);
