import { createContext, useContext, useState, useEffect, FC, PropsWithChildren, Dispatch, SetStateAction } from 'react';
import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_KEYS } from '@/utilities/localStorage';

export type PageSize = 10 | 20 | 50 | 100 | 1000;
export type PageSizes = Array<PageSize>;

interface SettingsContextType {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
  showInspector: boolean;
  setShowInspector: Dispatch<SetStateAction<boolean>>;
  assetSize: Array<number>;
  setAssetSize: Dispatch<SetStateAction<Array<number>>>;
  galleryView: 'tile' | 'list';
  setGalleryView: Dispatch<SetStateAction<'tile' | 'list'>>;
  pageSizes: PageSizes;
  assetsPerPage: PageSize;
  setAssetsPerPage: Dispatch<SetStateAction<PageSize>>;
}

const UserSettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const UserSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const pageSizes: PageSizes = [10, 20, 50, 100, 1000];

  const [showSidebar, setShowSidebar] = useState<boolean>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.showSidebar);
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [showInspector, setShowInspector] = useState<boolean>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.showInspector);
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const [assetSize, setAssetSize] = useState<Array<number>>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.assetSize);
    return storedValue !== null ? JSON.parse(storedValue) : [20];
  });

  const [galleryView, setGalleryView] = useState<'tile' | 'list'>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.galleryView);
    return storedValue !== null ? JSON.parse(storedValue) : 'tile';
  });

  const [assetsPerPage, setAssetsPerPage] = useState<PageSize>(() => {
    const storedValue = getLocalStorage(LOCAL_STORAGE_KEYS.assetsPerPage);
    return storedValue !== null ? JSON.parse(storedValue) : 20;
  });

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.showSidebar, JSON.stringify(showSidebar));
  }, [showSidebar]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.showInspector, JSON.stringify(showInspector));
  }, [showInspector]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.assetSize, JSON.stringify(assetSize));
  }, [assetSize]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.galleryView, JSON.stringify(galleryView));
  }, [galleryView]);

  useEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEYS.assetsPerPage, JSON.stringify(assetsPerPage));
  }, [assetsPerPage]);

  return (
    <UserSettingsContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        showInspector,
        setShowInspector,
        assetSize,
        setAssetSize,
        galleryView,
        setGalleryView,
        pageSizes,
        assetsPerPage,
        setAssetsPerPage,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettings = (): SettingsContextType => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
