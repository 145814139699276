import { createContext, useContext, useMemo, PropsWithChildren, FC } from 'react';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import algoliasearchHelper, { AlgoliaSearchHelper } from 'algoliasearch-helper';
import recommend, { RecommendClient } from '@algolia/recommend';
import { useAccount } from '@/hooks/data/account/useAccount';
import { SearchProvider } from '@/context/SearchContext';
import { useAuth0 } from '@auth0/auth0-react';

type AlgoliaContextType = {
  algoliaClient: AlgoliaSearchHelper;
  algoliaBaseClient: SearchClient;
  recommendClient: RecommendClient;
  algoliaIndexes: { default: string; [key: string]: string };
};

const AlgoliaContext = createContext<AlgoliaContextType | undefined>(undefined);

export const AlgoliaProvider: FC<PropsWithChildren> = ({ children }) => {
  const { profile } = useAccount();
  const { isAuthenticated, isLoading } = useAuth0();

  const algoliaBaseClient = useMemo(() => {
    if (!profile) {
      return null;
    }
    const { appId, apiKey } = profile.search;
    return appId && apiKey ? algoliasearch(appId, apiKey) : null;
  }, [profile]);

  const algoliaClient = useMemo(() => {
    if (!algoliaBaseClient || !profile?.search.algoliaIndexes) {
      return null;
    }
    return algoliasearchHelper(algoliaBaseClient, profile.search.algoliaIndexes.default, {
      hitsPerPage: 100,
      maxValuesPerFacet: 5,
    });
  }, [algoliaBaseClient, profile]);

  const recommendClient = useMemo(() => {
    if (!profile) {
      return null;
    }
    const { appId, apiKey } = profile.search;
    return appId && apiKey ? recommend(appId, apiKey) : null;
  }, [profile]);

  if (!isAuthenticated || isLoading || !profile || !algoliaBaseClient || !algoliaClient || !recommendClient) {
    return null;
  }

  return (
    <AlgoliaContext.Provider
      value={{ algoliaClient, algoliaBaseClient, recommendClient, algoliaIndexes: profile.search.algoliaIndexes }}
    >
      <SearchProvider>{children}</SearchProvider>
    </AlgoliaContext.Provider>
  );
};

export const useAlgolia = () => {
  const context = useContext(AlgoliaContext);

  if (context === undefined) {
    throw new Error('useAlgolia must be used within an AlgoliaProvider');
  }

  return context;
};
