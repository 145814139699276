import { PropsWithChildren, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import { Asset } from '@/types/asset';
import { uniq } from 'lodash';

type AssetSelectionContextType = {
  selectedAssetIds: Array<{ id: string; name: string }>;
  toggleAssetSelection: ({
    id,
    name,
    assets,
    index,
    metaKey,
    shiftKey,
  }: {
    id: string;
    name: string;
    assets: Array<Asset>;
    index: number;
    metaKey?: boolean;
    shiftKey?: boolean;
    ctrlKey?: boolean;
  }) => void;
  selectAllAssets: (allAssetIds: Array<{ id: string; name: string }>) => void;
  deselectAllAssets: () => void;
  contextAssetSelection: ({ id, name }: { id: string; name: string }) => void;
  setSelectedAssetIds: Dispatch<SetStateAction<Array<{ id: string; name: string }>>>;
};

const AssetSelectionContext = createContext<AssetSelectionContextType | undefined>(undefined);

export function AssetSelectionProvider({ children }: PropsWithChildren) {
  const [selectedAssetIds, setSelectedAssetIds] = useState<Array<{ id: string; name: string }>>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);

  const contextAssetSelection = ({ id, name }: { id: string; name: string }) => {
    if (!selectedAssetIds.some(({ id: assetId }) => assetId === id)) {
      setSelectedAssetIds([{ id, name }]);
    }
  };
  const toggleAssetSelection = ({
    id,
    name,
    assets,
    index,
    metaKey,
    shiftKey,
    ctrlKey,
  }: {
    id: string;
    name: string;
    assets: Array<Asset>;
    index: number;
    metaKey?: boolean;
    shiftKey?: boolean;
    ctrlKey?: boolean;
  }) => {
    let newSelectedAssetIds: Array<{ id: string; name: string }>;
    const newLastSelectedIndex = index;
    if (!metaKey && !shiftKey && !ctrlKey) {
      if (selectedAssetIds.some(({ id: assetId }) => assetId === id)) {
        newSelectedAssetIds = selectedAssetIds.filter(({ id: assetId }) => assetId !== id);
      } else {
        newSelectedAssetIds = [{ id, name }];
      }
    } else if (shiftKey) {
      if (lastSelectedIndex >= index) {
        const selectedOnes = assets?.slice(index, lastSelectedIndex).map(({ id, name }) => ({ id, name }));
        newSelectedAssetIds = selectedAssetIds.concat(selectedOnes);
      } else {
        const selectedOnes = assets.slice(lastSelectedIndex + 1, index + 1).map(({ id, name }) => ({ id, name }));
        newSelectedAssetIds = selectedAssetIds.concat(selectedOnes);
      }
    } else if (metaKey || ctrlKey) {
      const foundIndex = selectedAssetIds.findIndex((f) => f.id === id);
      // If found remove it to unselect it.
      if (foundIndex >= 0) {
        newSelectedAssetIds = [...selectedAssetIds.slice(0, foundIndex), ...selectedAssetIds.slice(foundIndex + 1)];
      } else {
        newSelectedAssetIds = [...selectedAssetIds, { id, name }];
      }
    }

    const finalList = assets
      ? assets.filter((f) => uniq(newSelectedAssetIds).find((a) => a.id === f.id)).map(({ id, name }) => ({ id, name }))
      : [];

    setSelectedAssetIds(finalList);
    setLastSelectedIndex(newLastSelectedIndex);
  };

  const selectAllAssets = (allAssetIds: Array<{ id: string; name: string }>) => {
    setSelectedAssetIds(allAssetIds);
  };

  const deselectAllAssets = () => {
    setSelectedAssetIds([]);
  };

  return (
    <AssetSelectionContext.Provider
      value={{
        selectedAssetIds,
        toggleAssetSelection,
        selectAllAssets,
        deselectAllAssets,
        contextAssetSelection,
        setSelectedAssetIds,
      }}
    >
      {children}
    </AssetSelectionContext.Provider>
  );
}

export function useAssetSelection() {
  const context = useContext(AssetSelectionContext);
  if (context === undefined) {
    throw new Error('useAssetSelection must be used within an AssetSelectionProvider');
  }
  return context;
}
