const allowLogs: boolean = process.env.DISABLE_DEV_LOGS !== 'true';

type ConsoleMethodType = 'log' | 'error' | 'info' | 'success' | 'warn';

const consoleMethod = (method: ConsoleMethodType = 'log', force: boolean = false) =>
  function (...args: Array<any>) {
    if ((process.env.NODE_ENV === 'development' && allowLogs) || force) {
      const prepend = method === 'success' ? colorLog('✅', '#00FA9A') : [];

      // eslint-disable-next-line no-console
      return (console as any)[method.replace('success', 'log')].apply(console, [...prepend, ...args]);
    }
  };

export const log = consoleMethod('log');
export const error = consoleMethod('error');
export const info = consoleMethod('info');
export const success = consoleMethod('success');
export const warn = consoleMethod('warn');

export const logProd = consoleMethod('log', true);
export const errorProd = consoleMethod('error', true);
export const infoProd = consoleMethod('info', true);
export const successProd = consoleMethod('success', true);
export const warnProd = consoleMethod('warn', true);

export const colorLog = (message: string, color: string | null = null): [string, string] => {
  return [`%c ${message} %s`, `color: ${color}`];
};
