import { PropsWithChildren, createContext, useContext, useState } from 'react';

type TrashSelectionContextType = {
  selectedTrashIds: Array<string>;
  toggleTrashSelection: (trashId: string) => void;
  selectAllTrash: (allTrashIds: Array<string>) => void;
  deselectAllTrash: () => void;
};

const TrashSelectionContext = createContext<TrashSelectionContextType | undefined>(undefined);

export function TrashSelectionProvider({ children }: PropsWithChildren) {
  const [selectedTrashIds, setSelectedTrashIds] = useState<Array<string>>([]);

  const toggleTrashSelection = (trashId: string) => {
    if (selectedTrashIds.includes(trashId)) {
      setSelectedTrashIds(selectedTrashIds.filter((id) => id !== trashId));
    } else {
      setSelectedTrashIds([...selectedTrashIds, trashId]);
    }
  };

  const selectAllTrash = (allIds: Array<string>) => {
    setSelectedTrashIds(allIds);
  };

  const deselectAllTrash = () => {
    setSelectedTrashIds([]);
  };

  return (
    <TrashSelectionContext.Provider
      value={{
        selectedTrashIds: selectedTrashIds,
        toggleTrashSelection: toggleTrashSelection,
        selectAllTrash: selectAllTrash,
        deselectAllTrash: deselectAllTrash,
      }}
    >
      {children}
    </TrashSelectionContext.Provider>
  );
}

export function useTrashSelection() {
  const context = useContext(TrashSelectionContext);
  if (context === undefined) {
    throw new Error('useTrashSelection must be used within an TrashSelectionProvider');
  }
  return context;
}
