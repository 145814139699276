import { FC, PropsWithChildren, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

export type DialogMode =
  | 'createFolder'
  | 'renameFolder'
  | 'createAlbum'
  | 'renameAlbum'
  | 'delete'
  | 'createTag'
  | 'editTag'
  | 'createUser'
  | 'editUser'
  | `edit${string}`
  | `create${string}`
  | null;

interface DialogData {
  id: string;
  name?: string;
  nodeId?: string;
  onConfirm?: () => void;
}

interface DialogContextType {
  openModal: (dialogId: string, mode?: DialogMode, defaultValue?: DialogData | null) => void;
  closeModal: () => void;
  openDialogId: string | null;
  setOpenDialogId: Dispatch<SetStateAction<string | null>>;
  dialogMode: DialogMode;
  setDialogMode: Dispatch<SetStateAction<DialogMode>>;
  dialogDefaultValue?: null | DialogData;
}

const DialogContext = createContext<DialogContextType | null>(null);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openDialogId, setOpenDialogId] = useState<DialogContextType['openDialogId']>(null);
  const [dialogMode, setDialogMode] = useState<DialogContextType['dialogMode']>(null);
  const [dialogDefaultValue, setDialogDefaultValue] = useState<DialogContextType['dialogDefaultValue']>(null);

  const openModal = (dialogId: string, mode?: DialogMode, defaultValue?: DialogData | null) => {
    setOpenDialogId(dialogId);
    setDialogMode(mode ?? null);
    setDialogDefaultValue(defaultValue ?? null);
  };

  const closeModal = () => {
    setOpenDialogId(null);
    setDialogMode(null);
    setDialogDefaultValue(null);
  };

  return (
    <DialogContext.Provider
      value={{
        openModal,
        closeModal,
        openDialogId,
        dialogMode,
        dialogDefaultValue,
        setOpenDialogId,
        setDialogMode,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
