import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const useAuthenticatedQuery = <TData = unknown, TError = unknown>(
  options: Omit<UseQueryOptions<TData, TError>, 'queryFn'> & { queryFn: (token: string) => Promise<TData> },
): UseQueryResult<TData, TError> => {
  const { getAccessTokenSilently } = useAuth0();

  const authenticatedQueryFn = async () => {
    const token = await getAccessTokenSilently();
    return options.queryFn(token);
  };

  return useQuery<TData, TError>({
    ...options,
    queryFn: authenticatedQueryFn,
  });
};

/**
 * A hook that takes a 'plain' function with arguments (params: any, token?:
 * string) => ... and returns a function that can be called with just (params),
 * while token is auto-injected prior to request made.
 *
 * Really this function exists just as a shortcut to not rewrite massively
 * existing *QueryFn functions.
 */
export const useAuthenticatedQueryFn = <T, R>(queryFn: (params: T, token?: string) => Promise<R>) => {
  const { getAccessTokenSilently } = useAuth0();

  return useCallback(
    async (params: T): Promise<R> => {
      const token = await getAccessTokenSilently();

      return queryFn(params, token);
    },
    [queryFn, getAccessTokenSilently],
  );
};
