import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { AssetSelectionProvider } from '@/context/AssetSelectionContext';
import { TreeContextProvider } from '@/context/TreeContext';
import { CommandContextProvider } from '@/context/CommandContext';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Toaster } from '@/components/ui/toaster';
import { TooltipProvider } from '@/components/ui/tooltip';
import { ThemeProvider } from 'next-themes';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DialogProvider } from '@/context/DialogContext';
import { HistoryProvider } from '@/context/HistoryContext';
import { UserSettingsProvider } from '@/context/UserSettingsContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from '@/components/ui/use-toast';
import { errorProd, log } from '@/utilities/log';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TrashSelectionProvider } from '@/context/TrashSelectionContext';
import { AlgoliaProvider } from '@/context/AlgoliaContext';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertTriangle, Loader2 } from 'lucide-react';
import Router from 'next/router';
import { DatatableSelectionProvider } from '@/context/DatatableSelectionContext';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '2892ff55-6be3-4ced-bc98-bbd922e214d5',
  clientToken: 'pubc3be883b4db3f3f8820d959f3842dda5',
  site: 'datadoghq.com',
  service: 'dam-frontend',
  env: process.env.NEXT_PUBLIC_ENVIRONMENT,
  version: process.env.NEXT_PUBLIC_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, refetchOnMount: false, retry: false },
    mutations: {
      onError: (error) => {
        errorProd(error);
        toast({
          title: 'Mutation Error',
          description: error.message,
        });
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      errorProd(error);
      toast({
        title: 'Query Error',
        description: error.message,
      });
    },
  }),
});

type TenantData = {
  tenant: {
    id: string;
    slug: string;
    name: string;
    auth0: {
      organizationId: string;
    };
  };
};

const Loading = () => (
  <div className="flex min-h-[100vh] items-center justify-center bg-white dark:bg-neutral-950">
    <div className="flex items-center justify-center gap-2">
      <Loader2 className="animate-spin stroke-neutral-950 dark:stroke-white" />
      Loading...
    </div>
  </div>
);

export default function App({ Component, pageProps }: AppProps) {
  if (
    !process.env.NEXT_PUBLIC_AUTH_DOMAIN ||
    !process.env.NEXT_PUBLIC_AUTH_CLIENT_ID ||
    !process.env.NEXT_PUBLIC_AUTH_ORGANIZATION_ID
  ) {
    throw new Error('Auth0 variables are not set');
  }

  const [tenantData, setTenantData] = useState<TenantData | null>(null);
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const { pathname, search, hostname, protocol } = window.location;
    if (pathname === '/login') {
      setRedirecting(true);
      const urlParams = new URLSearchParams(search);
      const organizationName = urlParams.get('organization_name');
      const redirectUrl = process.env.NODE_ENV === 'development' ? 'localhost:8001' : hostname;

      const url = `https://${process.env.NEXT_PUBLIC_AUTH_DOMAIN}/authorize${search}&response_type=code&client_id=${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}&redirect_uri=${protocol}//${organizationName}.${redirectUrl}`;
      void Router.replace(url);
    }
  }, []);

  useEffect(() => {
    const fetchTenantData = async () => {
      const hostname = window.location.hostname;
      try {
        const initUrl =
          process.env.NODE_ENV === 'development'
            ? `http://${hostname}:3001/api/v1/internal/init`
            : `/api/v1/internal/init`;

        const res = await fetch(initUrl);

        if (!res.ok) {
          throw new Error(res.statusText);
        }

        const siteConfig: TenantData = await res.json();
        setTenantData(siteConfig);
        setLoading(false);
      } catch (err) {
        log('Failed to fetch tenant data:', err);
        setLoading(false);
      }
    };

    void fetchTenantData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (redirecting) {
    return (
      <div className="flex min-h-[100vh] items-center justify-center  bg-neutral-950 text-white">
        <div className="flex flex-col items-center gap-5 rounded-lg bg-yellow-500/10 p-10">Redirecting...</div>
      </div>
    );
  }

  if (!tenantData) {
    return (
      <div className="flex min-h-[100vh] items-center justify-center  bg-neutral-950 text-white">
        <div className="flex flex-col items-center gap-5 rounded-lg bg-yellow-500/10 p-10">
          <AlertTriangle className="mr-2 size-12 stroke-yellow-500" strokeWidth={1} />
          Tenant doesn&apos;t exist
        </div>
      </div>
    );
  }

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}
      onRedirectCallback={(appState?: AppState) => {
        void Router.replace(appState?.returnTo ?? '/');
      }}
      authorizationParams={{
        audience: 'https://internal.api.dam.lookbooks.com',
        organization: tenantData.tenant.auth0.organizationId,
        redirect_uri: typeof window === 'undefined' ? undefined : window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <AuthWall>
          <QueryClientProvider client={queryClient}>
            <UserSettingsProvider>
              <DialogProvider>
                <HistoryProvider>
                  <TooltipProvider delayDuration={100}>
                    <AssetSelectionProvider>
                      <DatatableSelectionProvider>
                        <TrashSelectionProvider>
                          <TreeContextProvider>
                            <AlgoliaProvider>
                              <div vaul-drawer-wrapper="" className="min-h-[100vh] bg-white dark:bg-neutral-950">
                                <DndProvider backend={HTML5Backend}>
                                  <CommandContextProvider>
                                    <Component {...pageProps} />
                                  </CommandContextProvider>
                                </DndProvider>
                              </div>
                              <Toaster />
                              <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                            </AlgoliaProvider>
                          </TreeContextProvider>
                        </TrashSelectionProvider>
                      </DatatableSelectionProvider>
                    </AssetSelectionProvider>
                  </TooltipProvider>
                </HistoryProvider>
              </DialogProvider>
            </UserSettingsProvider>
          </QueryClientProvider>
          <div id="portal" />
        </AuthWall>
      </ThemeProvider>
    </Auth0Provider>
  );
}

const AuthWall: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      void loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  return children;
};
