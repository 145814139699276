import { useRouter } from 'next/router';
import { createContext, useContext, ReactNode, useCallback, Dispatch, useState, SetStateAction } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { UiState } from 'instantsearch.js';
import { useAlgolia } from '@/context/AlgoliaContext';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import { stateMapping } from '@/utilities/search/stateMapping';
import singletonRouter from 'next/router';
import qs from 'qs';

type SearchContextType = {
  navigateToResults: (uiState: UiState) => Promise<boolean>;
  showDetails: boolean;
  setShowDetails: Dispatch<SetStateAction<boolean>>;
};

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();

  const [showDetails, setShowDetails] = useState(false);

  const { algoliaBaseClient, algoliaIndexes } = useAlgolia();

  const navigateToResults = useCallback(
    (uiState: UiState) =>
      router.replace({
        pathname: '/search',
        query: qs.stringify(stateMapping(algoliaIndexes.default).stateToRoute(uiState)),
      }),

    [algoliaIndexes, router.replace],
  );

  return (
    <SearchContext.Provider
      value={{
        navigateToResults,
        showDetails,
        setShowDetails,
      }}
    >
      <InstantSearch
        searchClient={algoliaBaseClient}
        indexName={algoliaIndexes.default}
        routing={{
          router: createInstantSearchRouterNext({
            singletonRouter,
            routerOptions: {
              cleanUrlOnDispose: false,
            },
          }),
          stateMapping: stateMapping(algoliaIndexes.default),
        }}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <Configure attributesToRetrieve={['*']} filters={String(router.query.filters ?? '')} />

        {children}
      </InstantSearch>
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
