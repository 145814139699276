import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

type HistoryContextType = {
  history: Array<string>;
  setHistory: (value: Array<string>) => void;
  previousPage?: string;
};

const HistoryContext = createContext<HistoryContextType>({
  history: [],
  setHistory: () => {},
  previousPage: undefined,
});

export const HistoryProvider = ({ children }: PropsWithChildren) => {
  const [history, setHistory] = useState<Array<string>>([]);
  const router = useRouter();
  const previousPage = useMemo(() => history[history.length - 2], [history]);

  useEffect(() => {
    // Push the current path to history stack on route change
    const handleRouteChange = (url: string) => {
      setHistory((prevHistory) => [...prevHistory, url]);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <HistoryContext.Provider value={{ history, setHistory, previousPage }}>{children}</HistoryContext.Provider>;
};

// Custom hook to access the history context
export const useHistory = () => useContext(HistoryContext);
