import { createContext, useState, FC, PropsWithChildren, useContext } from 'react';

export interface Command {
  apply: () => any;
  undo: () => void;
  redo: () => void;
  update?: (params: any) => void;
}

export interface CommandContextValue {
  apply: (command: Command) => any;
  insert: (command: Command) => void;
  undo: () => void;
  redo: () => void;
  history: Array<Command>;
  pointer: number;
  setPointer: (value: number) => void;
}

export const CommandContext = createContext<CommandContextValue>({
  apply: () => {},
  insert: () => {},
  undo: () => {},
  redo: () => {},
  history: [],
  pointer: 0,
  setPointer: () => {},
});

export const CommandContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [history] = useState<Array<Command>>([]);
  const [pointer, setPointer] = useState<number>(0);

  const apply = (command: Command): any => {
    // Run the command
    const result = command.apply();

    // Insert command at pointer location and remove everything after
    // this is because once you do a new action, you can't "redo" any
    // undone command anymore.
    let currentPointer = 0;
    setPointer((prev) => {
      currentPointer = prev;
      return prev;
    });
    history.splice(currentPointer, Infinity, command);
    setPointer(currentPointer + 1);

    return result;
  };

  const insert = (command: Command): void => {
    // Insert a command into history that has already been applied
    let currentPointer = 0;
    setPointer((prev) => {
      currentPointer = prev;
      return prev;
    });
    history.splice(currentPointer, Infinity, command);
    setPointer(currentPointer + 1);
  };

  const undo = (): void => {
    if (history.length === 0) {
      return;
    }

    // Using setPointer to get the current value because it can be stale when called from components
    let currentPointer = 0;
    setPointer((prev) => {
      currentPointer = prev;
      return prev;
    });
    if (currentPointer > 0) {
      const newPointer = currentPointer - 1;
      setPointer(newPointer);
      history[newPointer].undo();
    }
  };

  const redo = (): void => {
    if (pointer >= history.length) {
      return;
    }

    history[pointer].redo();

    if (pointer < history.length) {
      setPointer((prev) => prev + 1);
    }
  };

  return (
    <CommandContext.Provider
      value={{
        apply,
        insert,
        undo,
        redo,
        history,
        pointer,
        setPointer,
      }}
    >
      {children}
    </CommandContext.Provider>
  );
};

export function useCommandContext() {
  return useContext(CommandContext);
}
